
import { defineComponent } from 'vue';
import PageHeader from '../components/PageHeader.vue'
import { getBlogPost, ParsedItem } from '../lib/Contentful'

interface BlogData {
  blog: ParsedItem;
}

export default defineComponent({
  name: 'BlogPost',
  components: {
    PageHeader
  },
  data(): BlogData {
    return {
      blog: null
    }
  },
  async mounted() {
    const url: string = this.$route.params.url as string
    await this.getBlogPost(url)
    const w = window as any;
    w.fbq('track', 'ViewContent');
  },
  methods: {
    async getBlogPost(url: string) {
      this.blog = await getBlogPost(url)
    }
  }
});
