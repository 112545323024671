import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "site-content" }
const _hoisted_2 = {
  key: 0,
  class: "section-content"
}
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-10 ml-md-auto mr-md-auto" }
const _hoisted_6 = { class: "rooms rooms--list" }
const _hoisted_7 = {
  class: "room staff",
  "data-aos": "fade-up",
  "data-aos-duration": "800"
}
const _hoisted_8 = { class: "room__body box" }
const _hoisted_9 = { class: "room__header" }
const _hoisted_10 = { class: "room__title" }
const _hoisted_11 = { class: "room__excerpt" }
const _hoisted_12 = { class: "room__img room__img-sm" }
const _hoisted_13 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_page_header, {
      image: "abandoned",
      title: _ctx.blog && _ctx.blog.title || ''
    }, null, 8, ["title"]),
    _createVNode("main", _hoisted_1, [
      (_ctx.blog)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, [
              _createVNode("div", _hoisted_4, [
                _createVNode("div", _hoisted_5, [
                  _createVNode("div", _hoisted_6, [
                    _createVNode("div", _hoisted_7, [
                      _createVNode("div", _hoisted_8, [
                        _createVNode("header", _hoisted_9, [
                          _createVNode("h2", _hoisted_10, [
                            _createVNode("a", {
                              href: '/meet-the-team/' + _ctx.blog.url,
                              innerHTML: _ctx.blog.title
                            }, null, 8, ["href", "innerHTML"])
                          ])
                        ]),
                        _createVNode("div", _hoisted_11, [
                          _createVNode("p", {
                            innerHTML: _ctx.blog.summary
                          }, null, 8, ["innerHTML"])
                        ])
                      ]),
                      _createVNode("figure", _hoisted_12, [
                        _createVNode("a", {
                          href: '/meet-the-team/' + _ctx.blog.url
                        }, [
                          _createVNode("img", {
                            src: _ctx.blog.image.file.url,
                            alt: _ctx.blog.title
                          }, null, 8, ["src", "alt"])
                        ], 8, ["href"])
                      ])
                    ])
                  ])
                ])
              ]),
              _createVNode("div", _hoisted_13, [
                _createVNode("div", {
                  class: "col-md-10 ml-md-auto mr-md-auto profile",
                  innerHTML: _ctx.blog.content
                }, null, 8, ["innerHTML"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}